import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Header from "../components/header"
import AnimationWrapper from "../components/animationWrapper"
import DownloadSection from "../components/downloadSection"
import ContactSection from "../components/contactSection"
import Seo from "../components/seo"

const DownloadPage = ({data, location}) => {

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Do pobrania" data={data.header} />
      </AnimationWrapper>

      <DownloadSection data={data.katalogi} location="Do pobrania" />

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "download" }, type: { eq: "page" } }) {
      frontmatter{
        title
        slug
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    katalogi: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(downloads)/"}},
        frontmatter: {category: {eq: "katalogi"}}
        }
    ) {
      nodes {
        frontmatter {
          name
          file {
            publicURL
          }
        }
        id
      }
    }
    header: file(relativePath: {eq: "header-dark.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default DownloadPage
